@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.nav-link {
    position: relative;
    text-decoration: none;
    color: black;
  }

  body {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    background-color: rgba(235, 235, 235, 0.651);
  }

  .bgImg {
    position: relative;
    background-image: url('./Assets/img2.jpg');
    background-size: cover;
    min-height: 500px;
    padding: 50px;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center center;
    color: black;
    background-attachment: fixed;
}

.bgImg::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(12, 207, 221, 0.5); /* Change the color and opacity as needed */
    z-index: 1;
}

.bgImg > * {
    position: relative;
    z-index: 2;
}
  .nav-link::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1.8px;
    background-color: yellowgreen; /* Change this to your desired underline color */
    transition: width 0.3s ease;
  }
  .btn-floating:hover .fab{
    color: rgb(255, 255, 255);
  }
  .btn-floating:hover {
    background-color: rgb(0, 0, 0);
    border: #ffffff solid 1px;
    }
  .nav-link:hover::after {
    width: 100%;
  }
  .nav-link:hover{color:yellow}

  .dropsLinks:hover{
    color:rgb(255, 255, 255);
    background-color: #028391;
  }
  .dropsLinks{
    color:black;
  }

/* forservices */
.service-card {
  transition: box-shadow 0.3s;
}

.service-card:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}


.service-card img {
  transition: transform 0.5s;
}

.service-card:hover img {
  transform: rotate(3deg) scale(1.1);
}

.service-card .overlay {
  background: linear-gradient(to bottom, transparent 0%, transparent 50%, black 100%);
  transition: background 0.5s;
}

.service-card:hover .overlay {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0.7) 100%);
}

.service-card .desc {
  opacity: 0;
  transition: opacity 0.3s;
}

.service-card:hover .desc {
  opacity: 1;
}

.ugCourse{
  position: relative;
  letter-spacing: 5px;
 color: #0d6efd;
 text-transform: uppercase;
 line-height: 0.70em;
 outline: none;
 animation: animate 5s linear infinite;
 font-weight: 600;
}

.img-container {
  width: 100%; /* Full width of the column */
  padding-top: 100%; /* Aspect ratio 1:1 (square) */
  position: relative; /* Containing block for the absolutely positioned image */
}

.placement-img {
  position: absolute; /* Position absolutely within the container */
  top: 0;
  left: 0;
  width: 50%;
  height: 50%;
  object-fit: contain; /* Ensure the image maintains its aspect ratio */
  object-position: center; /* Center the image */
}

.Rank-img-container{
  width: 100%; /* Full width of the column */
  padding-top: 100%; /* Aspect ratio 1:1 (square) */
  position: relative; /* Containing block for the absolutely positioned image */
  text-align: center; /* Center align text */
}

/* creating wrapper image text */
.wrapper{
  width: 100%;
}
.wrapper img{
  max-width: 320px;
  float: left;
}

.VissionMission{
  width: 100%;
}

.circle{
  position: absolute;
  margin-top: -80px !important;
}

.circleSmall{
  position: absolute;
  margin-top: -50px !important;
  background-color: #03bcf4;

}
.box:hover{
  cursor: pointer;
}

.box:hover  .innerBox{
  background-color: #03bcf4;
  color: white;
}
.box:hover .circle{
  background-color: white;
}
.box:hover .icon{
 color: #03bcf4 !important;
}
.circle{
  background-color: #03bcf4;
}

/* Gallery */
.react-photo-album{
  margin-top: 20px !important;
  margin-bottom: 130px !important;
}

/* Container for the items */
.photo-grid {
width: 100%;
margin: 0 auto;
font-size: 0; /* Remove inline-block gap */
}

/* Style for each item */
#photo-container {
width: 25%; /* Set to 25% for 4 items in a row */
box-sizing: border-box;
padding: 8px;
display: inline-block;
font-size: 16px; /* Reset font size for content */
vertical-align: top; /* Align items to the top */
}

.react-photo-album{
margin: 0px !important;
}
.lg-react-element img:hover{
  filter: opacity(.9);
  transform: scale(1.01);
  cursor: pointer;
}
.lg-react-element img{
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.yarl__container {
  background: rgba(0, 0, 0, 0.6) !important;
}


/* for slider */
.slick-slider{
  margin:10px 0;
}
.slick-next:before,.slick-prev:before{
  color: black !important;
}

@media only screen and (max-width: 900px) {
  .slick-next:before,.slick-prev:before{
    display: none
  }
}
.slick-track{
  height: 140px;
  padding-left: 30px;
}


/* bg-img setting */

.bgImg_sec1 {
  position: relative;
  background-image: url(./Assets/hat.png);
  background-repeat: no-repeat;
  background-blend-mode: screen;
  padding: 5px;
 background-color: rgba(235, 235, 235, 0.651);
  
}
@media (min-width: 1100px) {
  .bgImg_sec1 {
    background-position:  right center; /* For large screens (lg) */
    background-size: 400px ;
  }
}
@media (min-width: 1600px) {
  .bgImg_sec1 {
    background-position: 75% center; /* For large screens (lg) */
    background-size: 500px ;
  }
}

@media (max-width: 1100px) {
  .bgImg_sec1 {
    background-position: right bottom; /* For medium (md) and small (sm) screens */
    background-size: 300px ;

  }
}


